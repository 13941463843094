// COLORS
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
/* stylelint-disable */
$color-white: #ffffff;
$color-off-white: #f7f9fa;
$color-black: #000000;
$color-primary: #1186ed;
$color-primary-dark: #005aa8;
$color-grey: #4b5c70;
$color-grey-light: #8994a1;
$color-pink-light: #ed85a1;
$color-pink-dark: #e04c74;
$color-purple-light: #942ded;
$color-purpleark: #7117bf;
$color-shade-of-black: #111517;

// new colors
$color-vanilla: #f6ffed;
$color-light-green-white: #e6f7ff;
$color-ligh-green: #57c527;
$color-light-purple: #5193ff;
// new white
$color-new-white: #fafafa;
$color-grey-orange: #e07b38;
$color-pink-red: #e04c74;
$color-grey-hue: #c4cad0;
$color-dark-yellow: #fdaa23;
$color-teal-green: #2aa887;

// fonts
$font-oxygen-sanseriff: Oxygen, sans-serif;
$font-quicksand-sanseriff: Quicksand, sans-serif;

$color-accordian-black: #111827;
$color-accordian-grey: #4b5c70;

$rebranding-color-orange-dark: #ed572f;

$color-new-black: #111517;

// rebranding
@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');

// colors
// colors
$rebranding-color-primary-dark: #1a3580;
$rebranding-color-primary-light: #3153b2;
$rebranding-color-orange: #ed572f;
$rebranding-color-black: #101820;
$rebranding-color-smoke-grey: #eef0f7;
$rebranding-color-white: #fafafa;
$rebranding-color-purple-dark: #20347b;

// h1,2,3,4,5,6
$rebranding-font-archivo-sanseriff: Archivo, sans-serif;
$rebranding-font-space-grotesk-sanseriff: 'Space Grotesk', sans-serif;

// breakpoints
$breakpoint-mobile: 767px;
$breakpoint-tablet: 1280px;
