// padding
.p-0 {
	padding: 0;
}

.p-1 {
	padding: 1rem;
}

.p-2 {
	padding: 2rem;
}

.p-3 {
	padding: 3rem;
}

.p-4 {
	padding: 4rem;
}

.p-5 {
	padding: 5rem;
}

.px-0 {
	padding-left: 0;
	padding-right: 0;
}

.px-1 {
	padding: 0 1rem;
}

.px-2 {
	padding: 0 2rem;
}

.px-3 {
	padding: 0 3rem;
}

.px-4 {
	padding: 0 4rem;
}

.px-5 {
	padding: 0 5rem;
}

.py-0 {
	padding-top: 0;
}

.py-1 {
	padding: 1rem 0;
}

.py-2 {
	padding: 2rem 0;
}

.py-3 {
	padding: 3rem 0;
}

.py-4 {
	padding: 4rem 0;
}

.py-5 {
	padding: 5rem 0;
}

.pl-0 {
	padding-left: 0;
}

.pl-1 {
	padding-left: 1rem;
}

.pl-2 {
	padding-left: 2rem;
}

.pl-3 {
	padding-left: 3rem;
}

.pl-4 {
	padding-left: 4rem;
}

.pl-5 {
	padding-left: 5rem;
}

.pr-0 {
	padding-right: 0;
}

.pr-1 {
	padding-right: 1rem;
}

.pr-2 {
	padding-right: 2rem;
}

.pr-3 {
	padding-right: 3rem;
}

.pr-4 {
	padding-right: 4rem;
}

.pr-5 {
	padding-right: 5rem;
}

.pt-0 {
	padding-top: 0;
}

.pt-1 {
	padding-top: 1rem;
}

.pt-2 {
	padding-top: 2rem;
}

.pt-3 {
	padding-top: 3rem;
}

.pt-4 {
	padding-top: 4rem;
}

.pt-5 {
	padding-top: 5rem;
}

.pb-0 {
	padding-bottom: 0;
}

.pb-1 {
	padding-bottom: 1rem;
}

.pb-2 {
	padding-bottom: 2rem;
}

.pb-3 {
	padding-bottom: 3rem;
}

.pb-4 {
	padding-bottom: 4rem;
}

.pb-5 {
	padding-bottom: 5rem;
}
