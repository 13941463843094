@import './variables';
@import './alignment';
@import './btn';

.form {
	@media only screen and (min-width: 320px) and (max-width: 830px) {
		padding: 0 1rem;
	}

	@media only screen and (min-width: 1024px) {
		margin-right: 2rem;
	}

	&_logo {
		display: flex;
		justify-content: center;
	}

	&_heading {
		display: flex;
		justify-content: center;
		color: $color-grey;
		font-size: 3rem;
		font-weight: 400;
		font-family: 'Quicksand', sans-serif;
	}

	&_submit {
		@extend ._middle;

		&-button {
			@extend ._form_submit;
		}
	}

	&_result {
		@extend ._middle;

		width: 100%;

		&-btn {
			@extend ._form_submit;

			padding: 0.6rem 5rem !important;
		}
	}

	.ant-form-item {
		margin-bottom: 1rem;
	}
}
