.f-h2 {
	font-size: 28px;
}

.f-p {
	font-size: 16px;
	font-weight: 400;
	margin: 1rem;
}

.link-style {
	&:hover {
		cursor: pointer;
	}
}
