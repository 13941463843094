// margin
.m-0 {
	margin: 0;
}

.m-1 {
	margin: 1rem;
}

.m-2 {
	margin: 2rem;
}

.m-3 {
	margin: 3rem;
}

.m-4 {
	margin: 4rem;
}

.m-5 {
	margin: 5rem;
}

.mx-0 {
	margin-left: 0;
	margin-right: 0;
}

.mx-1 {
	margin: 0 1rem;
}

.mx-2 {
	margin: 0 2rem;
}

.mx-3 {
	margin: 0 3rem;
}

.mx-4 {
	margin: 0 4rem;
}

.mx-5 {
	margin: 0 5rem;
}

.my-0 {
	margin-top: 0;
}

.my-1 {
	margin: 1rem 0;
}

.my-2 {
	margin: 2rem 0;
}

.my-3 {
	margin: 3rem 0;
}

.my-4 {
	margin: 4rem 0;
}

.my-5 {
	margin: 5rem 0;
}

.ml-0 {
	margin-left: 0;
}

.ml-1 {
	margin-left: 1rem;
}

.ml-2 {
	margin-left: 2rem;
}

.ml-3 {
	margin-left: 3rem;
}

.ml-4 {
	margin-left: 4rem;
}

.ml-5 {
	margin-left: 5rem;
}

.mr-0 {
	margin-right: 0;
}

.mr-1 {
	margin-right: 1rem;
}

.mr-2 {
	margin-right: 2rem;
}

.mr-3 {
	margin-right: 3rem;
}

.mr-4 {
	margin-right: 4rem;
}

.mr-5 {
	margin-right: 5rem;
}

.mt-0 {
	margin-top: 0;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem;
}

.mt-3 {
	margin-top: 3rem;
}

.mt-4 {
	margin-top: 4rem;
}

.mt-5 {
	margin-top: 5rem;
}

.mb-0 {
	margin-bottom: 0;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.mb-4 {
	margin-bottom: 4rem;
}

.mb-5 {
	margin-bottom: 5rem;
}
